:root {
  --blueLink: #06c;
  --blueBackground: #acdae5;
  --bodyText: #222;
}

html {
  line-height: normal;
  overflow-y: scroll;
}

body {
  background-color: var(--blueBackground);
  font-size: 14px;
  margin: 0;
  color: var(--bodyText);
}

a {
  color: var(--blueLink);
  text-decoration: none;
}

* {
  font-family: "Segoe UI Emoji", "Avenir Next", Avenir, "Segoe UI", "Helvetica Neue", Helvetica, sans-serif;
  touch-action: manipulation;
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
